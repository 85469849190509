<script setup lang="ts">
import Button from '../design-system/button.vue';
import UserSettingsBaseContainer from './user-settings-base-container.vue';
import { trackingMethods } from '~~/plugins/analytics.client';
import GenericResponse from '~/components/user-interface/generic-error.vue';
import { useLocaleStore } from '~/stores/locale.store';
import { useSiteStore } from '~/stores/site.store';
import type { severity } from '~/interfaces/dto/general/toast-messages';
const { $authService } = useServices();
const site = useSiteStore();
const localeStore = useLocaleStore();
import ToggleSwitch from 'primevue/toggleswitch';
const { $t, $enabled } = useNuxtApp();

// Values for normal drop down field
const langOptions: globalThis.Ref<string[]> = ref();

const switchClasses = 'flex items-center text-xs pb-0';

interface ICommunicationDetails {
  preferredCulture: string;
  platformName: string;
  preferredCommunicationChannel: string;
  allowPushNotificationMarketingMessages: boolean;
  allowPushNotificationInformativeMessages: boolean;
  allowPushNotificationReEngagingMessages: boolean;
  allowEmailMarketingMessages: boolean;
  allowEmailInformativeMessages: boolean;
  allowEmailReEngagingMessages: boolean;
  allowSmsMarketingMessages: boolean;
  allowSmsInformativeMessages: boolean;
  allowSmsReEngagingMessages: boolean;
}

interface IBankingPreferences {
  allowWithdrawalReversal: boolean;
}

const communicationDetails = ref<ICommunicationDetails>({
  preferredCulture: '',
  platformName: 'WebDesktop',
  preferredCommunicationChannel: '',
  allowPushNotificationMarketingMessages: false,
  allowPushNotificationInformativeMessages: false,
  allowPushNotificationReEngagingMessages: false,
  allowEmailMarketingMessages: false,
  allowEmailInformativeMessages: false,
  allowEmailReEngagingMessages: false,
  allowSmsMarketingMessages: false,
  allowSmsInformativeMessages: false,
  allowSmsReEngagingMessages: false,
});

const bankingPreferences = ref<IBankingPreferences>({
  allowWithdrawalReversal: false,
});

// submit validation
const valid = ref<boolean>(false);
const disabled = computed<boolean>(() => {
  return valid.value === false;
});

const activeLoader = ref<boolean>(false);
const responseType = ref<severity>();
const responseCode: globalThis.Ref<string> = ref('');
const displayError: globalThis.Ref<boolean> = ref(false);

async function updateSettings() {
  responseCode.value = '';
  activeLoader.value = true;

  // send GA tracking response to update settings
  await trackingMethods.myAccountSettingsUpdate();

  await $authService
    .updateSubscriptions(communicationDetails.value)
    .then((data) => {
      activeLoader.value = false;
      if (data) {
        responseType.value = 'success';
        responseCode.value = '0';
      } else {
        responseType.value = 'danger';
        responseCode.value = '1';
      }
    })
    .catch(() => {
      activeLoader.value = false;
      responseType.value = 'danger';
      responseCode.value = '1';
    });
}
function validate(value: string) {
  if (value === undefined) {
    valid.value = false;
    return;
  } else if (value.length < 1) {
    valid.value = false;
    return;
  } else {
    valid.value = true;
  }
}

function createLangSelection() {
  const cultures = !!site.getConfig.cultures
    ? site.getConfig.cultures
    : site.getConfig.Cultures;
  if (!!cultures) {
    localeStore.setLocaleOptions(cultures);
    langOptions.value = localeStore.getLocaleOptions;
  }
}

onBeforeMount(async () => {
  activeLoader.value = true;
  displayError.value = false;
  try {
    const data = await $authService.fetchSubscriptions();
    // remember to map culture to culture translation for the view
    communicationDetails.value = { ...communicationDetails.value, ...data };
    //TODO: make raging API Call for banking pref
    bankingPreferences.value.allowWithdrawalReversal = true;

    activeLoader.value = false;
  } catch (e) {
    activeLoader.value = false;
    console.error(e);
  }
});

onMounted(async () => {
  createLangSelection();
});

watchEffect(() => {
  validate(communicationDetails.value.preferredCommunicationChannel);
});
</script>

<template>
  <GenericResponse v-if="displayError" class="h-min" state="Error">
    {{ $t('there-was-a-problem') }}
  </GenericResponse>
  <UserSettingsBaseContainer v-else class="mb-3">
    <!--    Channel and language-->
    <LazyUserInterfaceGenericLoader v-if="activeLoader" :standalone="true" />
    <div v-else class="user-comm-pref">
      <div class="sm:mt-4 mx-auto sm:max-w-[348px] bg-layer-1 sm:rounded-lg">
        <p class="ml-4 py-2 sm:py-3 font-bold">
          {{ $t('account-settings') }}
        </p>
        <hr class="w-full border-light-200 dark:border-dark-900" />
        <!-- Marketing messages-->
        <div class="mx-4">
          <div class="mt-3">
            <p class="text-base-priority font-bold mt-0 mb-2">
              {{ $t('marketing-messages') }}
            </p>
          </div>
          <div class="text-xs">
            <p class="mt-0 mb-3">
              {{ $t('marketing-message-description') }}
            </p>
          </div>

          <!-- Channel and language-->
          <div class="grid grid-cols-3 gap-2">
            <div :class="switchClasses">
              <label for="marketing-email" class="mr-2">
                {{ $t('email') }}
              </label>
              <ToggleSwitch
                v-model="communicationDetails.allowEmailMarketingMessages"
                input-id="email-marketing"
              />
            </div>

            <div :class="switchClasses">
              <label for="push-marketing" class="mr-2">
                {{ $t('push') }}
              </label>
              <ToggleSwitch
                v-model="
                  communicationDetails.allowPushNotificationMarketingMessages
                "
                input-id="push-marketing"
              />
            </div>
            <div :class="switchClasses">
              <label for="sms-marketing" class="mr-2">
                {{ $t('sms') }}
              </label>
              <ToggleSwitch
                v-model="communicationDetails.allowSmsMarketingMessages"
                input-id="sms-marketing"
              />
            </div>
          </div>
        </div>

        <!-- Informative messages-->
        <div class="mx-4">
          <div class="mt-4">
            <p class="font-bold text-base-priority mb-2">
              {{ $t('informative-messages') }}
            </p>
          </div>
          <div class="text-xs">
            <p class="mt-0 mb-3">
              {{ $t('informative-messages-description') }}
            </p>
          </div>
          <!-- Channel and language-->
          <div class="grid grid-cols-3 gap-2">
            <div :class="switchClasses">
              <label for="email-informative" class="mr-2">
                {{ $t('email') }}
              </label>
              <ToggleSwitch
                v-model="communicationDetails.allowEmailInformativeMessages"
                input-id="email-informative"
              />
            </div>

            <div :class="switchClasses">
              <label for="push-informative" class="mr-2">
                {{ $t('push') }}
              </label>
              <ToggleSwitch
                v-model="
                  communicationDetails.allowPushNotificationInformativeMessages
                "
                input-id="push-informative"
              />
            </div>

            <div :class="switchClasses">
              <label for="sms-informative" class="mr-2">
                {{ $t('sms') }}
              </label>
              <ToggleSwitch
                v-model="communicationDetails.allowSmsInformativeMessages"
                input-id="sms-informative"
              />
            </div>
          </div>
        </div>

        <!-- Banking settings-->
        <div class="mx-4" v-if="$enabled('myaccount.enablewithdrawalrefund')">
          <div class="mt-4">
            <p class="font-bold text-base-priority mb-2">
              {{ $t('banking-settings') }}
            </p>
          </div>
          <div class="text-xs">
            <p class="mt-0 mb-3">
              {{ $t('banking-settings-description') }}
            </p>
          </div>
          <!-- Channel and language-->
          <div class="grid grid-cols-1 gap-2">
            <div :class="switchClasses">
              <label for="reversible-banking" class="mr-2">
                {{ $t('reversible-withdrawals') }}
              </label>
              <ToggleSwitch
                v-model="bankingPreferences.allowWithdrawalReversal"
                input-id="reversible-banking"
              />
            </div>
          </div>
        </div>
        <div class="text-sm w-full p-4">
          <Button
            class="mt-2 uppercase w-full justify-center text-sm"
            rounding="md"
            :disabled="disabled"
            @click="updateSettings"
          >
            {{ $t('update') }}
          </Button>
        </div>
      </div>
    </div>
    <div>
      <GenericResponse v-if="responseCode" :state="responseType">
        {{ $t(`response-code.${responseCode}`) }}
      </GenericResponse>
    </div>
  </UserSettingsBaseContainer>
</template>

<style lang="scss">
.user-comm-pref .p-inputswitch-slider {
  border: none;
}
</style>

<style lang="scss" scoped>
label {
  text-transform: capitalize;
  font-family: Inter;
  font-size: 12px;
  font-weight: bold;
}
</style>
